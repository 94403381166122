$(function () {
    var bsTables = $(document).find("[bsTable]");
    for (let bsTable of bsTables) {
        var tableName = bsTable.id;
        //if table has no id => generate and set new id
        if (tableName == '') {
            tableName = "bsTable_" + Math.floor(Math.random() * 1000);
            bsTable.id = tableName;
        }
        BSTableUtil.initTable({ 'tableName': tableName });  // only once per table
    }
});